@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap);
/* Google fonts */
/* Basic Setup starts */
*{
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
}
:root {
	--primary-color: #242020;
	--gray-color: #E8E3E3;
	--white-color: #fff;
	--red-color: #E92236;
  }
.bg-gray-color{
	background: #E8E3E3 !important;
	background: var(--gray-color) !important;
}
html{
	scroll-behavior: smooth;
	font-size: 100%;
}
body{
    font-family: 'Mulish', sans-serif;
}

h2,h3,h4,h5,h6,p,a{
	font-family: 'Mulish', sans-serif;
}
ul{
	list-style: none !important;
	padding-left: 0 !important;
	margin-bottom: 0;
}
a{
	text-decoration: none !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control:focus {
	box-shadow: none !important;
}
.form-check-input:focus {
	outline: 0;
	box-shadow: none !important;
}
/* Basic Setup ends */

/* Reusable component starts */
.pt-100{
	padding-top: 10rem;
}
.pb-60{
	padding-bottom: 6rem;
}
.ptb-40{
	padding-top: 4rem;
	padding-bottom: 4rem;
}
.primary-btn{
	display: block;
	background: #E92236 !important;
	border-color: #E92236 !important;
	color: #ffffff !important;
	font-size: 1.2rem;
	font-weight: 600;
	padding: .4rem 2.5rem;
	text-align: center;
	box-shadow: none !important;
	border-radius: .25rem;
}
/* Reusable component ends */


/* Header */
#header {
	border-bottom: 1px solid #eee;
	box-shadow: 0px 0px 10px #d6d6d675;
	position: sticky;
	top: 0;
	z-index: 9;
}
.header-main .nav-link {
	color: #242020;
	font-size: 1.1rem;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0 8px;
	padding-top: 35px;
	display: inline-block;
}
#responsive-navbar-nav {
	margin-top: -20px;
	margin-bottom: 8px;
}
.header-main .nav-link:last-of-type {
	margin-right: 0px !important;
}
.header-main .nav-item:hover .nav-link{
	color: #E92236;
	color: var(--red-color);
}
.header-main .nav-link:last-child {
	margin-right: 0px !important;
}
#responsive-navbar-nav {
	margin-bottom: 20px;
	margin-top: -10px;
}
.header-main .nav-link:hover{
	color: #E92236;
	color: var(--red-color);
}
.active.nav-link {
	background: #E92236;
	background: var(--red-color);
	color: #fff !important;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
#responsive-navbar-nav .dropdown-menu {
	position: absolute;
	width: 400px;
	padding: 0 15px 10px !important;
	right: 0 !important;
	left: auto;
	box-shadow: 0 10px 10px 3px rgba(0,0,0,.04);
	top: 110%;
	border: 1px solid #eee;
}
#responsive-navbar-nav .dropdown-menu a {
	color: #E92236;
	color: var(--red-color);
	font-size: .9rem;
}
.lang-select {
	margin-top: 35px;
	font-weight: 700;
	color: #e92236;
	background: transparent;
	border: 1px solid #242020;
	border-radius: 30px;
	margin-left: 15px;
	-webkit-appearance: none;
	padding: 0px 15px 2px;
	cursor: pointer;
}
/* Banner Css */
.banner-section{
	position: relative;
	background: url(/static/media/banner-bg.311a3b63.jpg) no-repeat;
	background-size: contain;
	background-position: left bottom;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.banner-section .slider-wrap{
	position: relative;
	background: url(/static/media/banner-curve.210b8112.png) no-repeat;
	background-size: contain;
	background-position: left bottom;
	width: 45%;
	overflow: hidden;
	z-index: 3;
}
.slider-wrap {
	
}
.slider-wrap .slick-dots {
	bottom: 15px !important;
}
.slider-wrap .slick-dots li button {
	width: 0;
	height: 0;
	padding: 0;
	cursor: pointer;
	color: #000;
	background: #fff;
	border-radius: 50%;
}
.slider-wrap .slick-dots li button::before {
	font-size: 15px;
	opacity: .8;
	color: #fff;
}
.slick-prev::before, .slick-next::before {
	font-size: 30px !important;
}
.banner-right-content{
	width: 50%;
	margin-right:25px;
}
.banner-title {
	color: #E92236;
	color: var(--red-color);
	font-size: 50px;
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 20px;
}
.banner-inp-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}
.banner-search-inp {
	height: 55px;
	background: #DDE2E2;
	border: 1px solid #ddd;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	margin-right: 8px;
	padding: 15px;
	width: 70%;
}
.banner-submit-btn {
	background: #E92236;
	background: var(--red-color);
	height: 55px;
	border: 0;
	padding: 15px;
	color: #fff;
	font-weight: 600;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	font-size: 1.1rem;
}
.banner-txt {
	margin: 0;
	font-size: 1rem;
	font-weight: 500;
}
.section-title{
	color: #242020;
	color: var(--primary-color);
	font-size: 2.5rem;
	text-align: center;
	font-weight: normal;
}
/* Service Section */
.service-section {
	border-top-right-radius: 150px;
}
.service-title{
	color: #242020;
	color: var(--primary-color);
	font-size: 1.2rem;
	font-weight: normal;
}
.service-para.card-text {
	color: #242020;
	color: var(--primary-color);
	font-size: 1rem;
}
.card-service-img-div {
	background: #E8E3E3;
	background: var(--gray-color);
	display: inline-block;
	height: 75px;
	width: 75px;
	border-radius: 50%;
	position: relative;
	padding: 10px;
	margin-bottom: 15px;
}
.service-card{
	position: relative;
	transition: all ease 0.5s;
}
.service-card:hover{
	background: #E92236;
	background: var(--red-color);
	color: #fff;
	color: var(--white-color);
}
.service-card:hover .service-para.card-text{
	color: #fff;
	color: var(--white-color);
}
.service-card:hover .service-title{
	color: #fff;
	color: var(--white-color);
}
.card-service-img {
	width: 85% !important;
	margin: 5px auto;
}

/* Delivery section starts */
#delivery .row.counter-row {
	margin-bottom: 2rem;
}
#delivery .counter-item h2 {
	color: #E92236;
	font-weight: 700;
	font-size: 1.8rem;
	margin-bottom: 0;
}
#delivery .counter-item span {
	color: #2E2E2E;
	font-size: .9rem;
	display: block;
}
#delivery .heading {
	text-align: center;
	margin-bottom: 1.3rem;
}
#delivery .heading p {
	margin-bottom: 0;
	font-size: 1rem;
}
#delivery .delivery-form .formInput .form-select {
	background-color: #DDE2E2;
	padding-top: .6rem;
	padding-bottom: .6rem;
	box-shadow: none !important;
	border: 0 !important;
}
#delivery .delivery-form .checkBtn {
	display: block;
	margin: 0 auto;
	background: #E92236 !important;
	border-color: #E92236 !important;
	font-size: 1.2rem;
	font-weight: 700;
	padding: .4rem 2.5rem;
	margin-top: 1rem;
	margin-bottom: .5rem;
	line-height: 2rem;
	text-align: center;
	box-shadow: none !important;
}
#delivery .delivery-form .see-more-info p {
	font-size: 1rem;
	text-align: center;
	margin-bottom: 0;
}
#delivery .delivery-form .see-more-info a {
	font-size: 1rem;
	font-weight: 600;
	color: #E92236;
	margin-left: .3rem;
}
/* Delivery section ends */

/* About section starts */
#about .about-wrap{
	background-image: url(/static/media/about-bg.3adaf09a.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
	border-top-right-radius: 150px;

}
.about-wrap::before {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #00000082;
	height: 100%;
	width: 100%;
	content: '';
	z-index: 1;
}
#about .about-text {
	padding: 5.5rem 0;
	position: relative;
	z-index: 2;
}
#about .about-text {
	padding: 5.5rem 0;
}
#about .about-text h2 {
	color: #FFFFFF;
	font-size: 1.8rem;
	font-weight: 700;
	margin-bottom: 1.5rem;
}
#about .about-text p {
	color: #ffffff;
	font-size: 1rem;
	margin-bottom: 1.5rem;
}
#about .about-text .about-btn {
	width: 125px;
	padding: .6rem 1rem;
	font-size: 1.1rem;
}
/* About section ends */

/* Testimonial */
.testimonial-card.card {
	border-bottom: 3px solid #e92236;
	border-radius: 5px;
}
.testimonial-img-div {
	display: flex;
	align-items: center;
}
.testimonial-img {
	width: 70px;
	margin-right: 20px;
}
.author-name {
	font-size: 16px;
	font-weight: 700;
	margin: 0;
	text-align: left;
}
.author-page {
	font-size: 14px;
	margin: 0;
	text-align: left;
}
/* Brand Section */
.brand-img-div {
	height: 50px;
	border: 1px solid #eee;
	margin: 8px 5px 0px;
	position: relative;
	border-radius: 4px;
}
.brand-img {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
/* Footer section starts */
#footer {
	background: #2E2E2E;
	color: #ffffff;
}
#footer li a {
	color: #ffffff;
}
.footer-top .left-inner li a {
	font-size: 1rem;
	font-weight: 500;
}
.footer-top .left-inner li span {
	font-size: 1rem;
	font-weight: 600;
	margin-right: .4rem;
}
.hide{
	opacity: 0;
}
.footer-top {
	padding: 2.5rem 0;
	padding-bottom: 1.2rem;
}
#footer a {
    text-decoration: none;
}
.footer-social ul li a {
	background: #E92236;
	background: var(--red-color);
	width: 4rem;
	height: 4rem;
	display: inline-block;
	line-height: 4rem;
	font-size: 2.2rem;
	border-radius: 50%;
	text-align: center;
	padding: 0 !important;
	color: #fff;
	margin: 0 .5rem;
}
/* .footer-widget.logo-widget {
	text-align: center;
} */
.social-list {
	align-items: center;
	/* justify-content: center; */
}
.footer-widget img {
	width: 15rem;
	height: auto;
	margin-left: 8.5rem;
}
.footer-widget .footer-logo {
    margin-bottom: 2.5rem;
}
.footer-widget p {
    line-height: 1.8;
    font-size: 1.7rem;
    font-weight: 400;
}

#footer ul {
	padding-left: 0;
	margin-bottom: 0;
	margin-top: 0;
}

.footer-social li a {
	font-size: 1rem;
	border: 1px solid #ffffff;
	padding: .4rem;
	display: block;
	text-align: center;
	width: 35px;
	height: 35px;
	line-height: 20px;
	border-radius: 50%;
	margin: 0 .5rem;
	outline: 0;
	transition: .2s ease-in-out;
}

.footer-social li a:hover {
    background-color: #E92236 ;
    color: #ffffff;
	border-color: #E92236 ;
}

.footer-social {
    margin-top: 2rem;
}
.footer-top-row2 {
	padding-top: 1rem;
}
.footer-top-row2 .left-inner {
	margin-top: 1rem !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.footer-top-row2 .right-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.footer-widget h3 {
	font-size: 1.4rem;
	padding-bottom: 1rem;
	font-weight: 700;
	margin-bottom: 0;
	text-transform: capitalize;
}
.footer-widget ul.footer-link li {
	margin: 0.2rem 0;
}
.footer-widget ul.footer-link li a {
	font-size: 1.2rem;
	font-weight: 500;
	color: #ffffff;
	line-height: 36px;
}
.footer-widget ul.footer-link li:hover a {
	color: #E92236 !important;
	color: var(--red-color) !important;
	text-decoration: underline !important;
}
.footer-widget ul.footer-link li a {
    transition: .3s ease all;
}
.footer-bottom {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 0;
	border-top: 1px solid #80808099;
}
.footer-bottom a {
	color: #ffffff;
	font-size: 0.9rem;
	margin-bottom: 0;
	margin: 0 .2rem;
	margin-top: .2rem;
}
.footer-bottom span {
	font-size: .9rem;
	margin-right: .3rem;
}
/* Footer section ends */

/* Company Info */
.custom-form-control {
	min-height: 45px !important;
	border: 1px solid #DDE2E2 !important;
	background: #DDE2E2 !important;
}
.form-label {
	font-weight: 600;
}
.info-right-img {
	border-radius: 4px;
}
.custom-btn {
	color: #fff !important;
	color: var(--white-color) !important;
	background: #E92236 !important;
	background: var(--red-color) !important;
	border-color: #E92236 !important;
	border-color: var(--red-color) !important;
	box-shadow: none !important;
	font-size: 1.2rem !important;
	font-weight: 600 !important;
	padding: 10px 40px !important;
	border-radius: 5px;
	display: inline-block;
}
.custom-btn.back-btn{
	background: #242020 !important;
	background: var(--primary-color) !important;
	border-color: #242020 !important;
	border-color: var(--primary-color) !important;
}
/************* Charges page starts ***********/
#charges .charges-baner img {
	width: 100%;
	object-fit: cover;
	margin-bottom: 2.5rem;
}
#charges .heading h2 {
	margin-bottom: 2.5rem;
}
.charges-tab-wrapper ul.nav-tabs {
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 0;
}
.charges-tab-wrapper .nav-tabs .nav-link {
	color: #000000 !important;
	background-color: transparent;
	border: none;
	font-weight: 700;
	font-size: 1.2rem;
	border-bottom: 3px solid transparent;
	border-radius: 0;
	padding: 0;
	padding-bottom: 0.4rem;
	transition: .2s all ease-in-out;
}
.charges-tab-wrapper .nav-tabs .nav-link.active {
	border-bottom: 3px solid #E92236;
}
.charges-tab-wrapper .nav-tabs .nav-link:hover {
	border-bottom: 3px solid #E92236;
}
.charges-tab-wrapper .tab-content table tr td {
	font-weight: 600;
}
.charges-tab-wrapper .charge-type h6 {
	font-weight: 700;
}
.charges-tab-wrapper {
	margin-bottom: 2.5rem;
}
/************* Charges page ends ***********/

/************* About Us page starts ***********/
#about-us .about-baner{
	background-image: url(/static/media/about-us.7849ca50.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
}
#about-us .about-baner h4 {
	color: #ffffff;
	font-size: 2rem;
	margin-bottom: 0;
	font-weight: 700;
	padding: 4rem 0;
}
#about-us .about-content {
	padding: 4rem 0;
}
#about-us .about-content h6 {
	font-size: 1.3rem;
	color: #000000;
	font-weight: 600;
	line-height: 1.8rem;
	margin-bottom: 2rem;
}
#about-us .about-content p {
	font-size: 1.2rem;
	color: #000000;
	margin-bottom: 0;
}
/************* About Us page ends ***********/


@media(min-width:768px) and (max-width:991px){
    .banner-submit-btn {
        padding: 7px;
    }
    .footer-widget ul.footer-link li a {
        font-size: 1rem;
    }
}
@media(max-width:991px){
    /* Header */
    .lang-select {
        margin-top: 0px;
        border-radius: 4px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 600;
    }
    #responsive-navbar-nav {
        margin: 0 0 15px;
        background: #dde2e2;
        padding: 0;
        border-radius: 5px;
    }
    .navbar-toggler {
        outline: 0 !important;
        box-shadow: none !important;
        background: #e92236 !important;
        position: relative;
        padding: 1px 4px !important;
    }
    .navbar-toggler-icon::after {
        font-family: "Fontawesome";
        content: "\f0c9";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        color: #fff;
        font-size: 22px;
    }
    .navbar-brand img {
        width: 160px;
    }
    .header-main .nav-link {
        font-size: .9rem;
        font-weight: 600;
        margin: 0;
        padding: 10px 15px !important;
        display: block;
    }
    .active.nav-link {
        border-radius: 5px;
    }
    #responsive-navbar-nav .dropdown-menu {
        position: relative;
        width: auto !important;
        padding: 10px;
        margin: 0 15px;
        background: #fff;
        border: 1px solid #fff;
    }
}
@media(max-width:767px){
    .banner-right-content {
        width: 100%;
        margin-right: 0;
    }
    .slick-prev::before, .slick-next::before {
        font-size: 20px !important;
    }
    .banner-section .slider-wrap {
        display: none;
    }
    .banner-section {
        min-height: 300px !important;
        max-height: 300px !important;
    }
    /* Banner */
    .banner-title {
        font-size: 36px;
    }
    .banner-submit-btn {
        padding: 0 5px;
        height: 45px;
        font-size: .9rem;
    }
    .banner-section {
        min-height: 460px;
    }
    .banner-search-inp {
        height: 45px;
    }
    .section-title {
        font-size: 1.8rem;
        font-weight: 600;
    }
    /* Service */
    .service-section {
        border-top-right-radius: 100px;
    }
    .slick-prev {
        left: -10px !important;
    }
    .slick-next {
        right: -10px !important;
    }
    /* Delivery */
    .counter-item{
        margin-bottom: 20px;
    }
    #delivery .row.counter-row {
        margin-bottom: .8rem;
    }
    /* About */
    #about .about-text {
        padding: 2.5rem 0;
    }
    #about .about-wrap {
        background-position: 100%;
    }
    #about .about-text p {
        text-align: justify;
    }
    /* Footer */
    .footer-widget{
        padding-top: 1.8rem;
    }
    .row.footer-top-row2 .right-inner img {
        width: 120px;
    }
    .footer-widget h3 {
        font-size: 1.2rem;
        padding-bottom: .5rem;
       
    }
    .h3-first-child{
        padding-top: 0rem !important;
    }
    .footer-widget ul.footer-link li {
        margin: 0.1rem 0;
    }
    .footer-widget ul.footer-link li a {
        font-size: 1rem;
        line-height: 30px;
    }
    .footer-widget .hide {
        display: none;
    }
    .footer-top-row2 .left-inner {
        margin-top: 0rem !important;
        align-items: flex-start;
        flex-direction: column;
    }
    .left-inner li {
        margin-bottom: 10px;
    }
    .footer-bottom {
        flex-direction: column;
    }
    /* About us page starts */
    #about-us .about-baner h4 {
	font-size: 1.6rem;
    }
    #about-us .about-content h6 {
	font-size: 1rem;
	margin-bottom: 1.3rem;
    }
    #about-us .about-content {
	padding: 3rem 0;
    }
    #about-us .about-content p {
	font-size: 0.9rem;
    }
    /* About us page ends */

    /* Charges page starts */
    #charges .charges-baner img {
	object-fit: cover;
	margin-bottom: 2rem;
	height: 200px;
    }
    #charges .heading h2 {
	margin-bottom: 2rem;
    }
    .charges-tab-wrapper ul.nav-tabs {
	margin-bottom: 0 !important;
    }
    .charges-tab-wrapper .nav-tabs .nav-link {
	font-size: 1rem;
	margin-bottom: 1.5rem;
	padding: 0.8rem !important;
	border: none !important;
	border-radius: 6px;
	display: inline-block;
	text-align: center;
    }
    .charges-tab-wrapper .nav-tabs .nav-link.active {
	background: #E92236;
	color: #ffffff !important;
    border: none !important;
    }
    .charges-tab-wrapper .tab-content .table {
	display: block;
	overflow-x: scroll;
    }
    /* Charges page ends */
}
@media(max-width:600px){
    
}
@media(max-width:414px){
    .banner-inp-wrapper {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-right-content {
        margin-top: 20px;
    }
}
